
// Farben für Links
// ==================================================================
@link-color:#009361;
@link-hover-color:@link-color+20;
@link-active-color:@link-color+40;

// Farben für den CornerButton
// ==================================================================
//@corner-button-color: #cc0000;  // Rot
//@corner-button-color: #009361;  // Grün
//@corner-button-color: #118BCD;  // Blau
@corner-button-color:@link-color;
@corner-button-hover-color:@link-hover-color;




// IMPORTE
// ==================================================================
@import "dk-corner-button.less";
@import "dk-window.less";
@import "dk-human-check.less";
@import "dk-menu-slider.less";
// @import "dk-devel-frame.less"; // GEHT NOCh NICHT
// @import "dk-tool-tip.less"; // GEHT NOCh NICHT
@import "dk-bootstrap-config.less";

//@import "";


@font-face {
    font-family: 'Com4FineReg';
    src: url('../libs/durklang/com4f_rg-webfont.eot');
    src: url('../libs/durklang/com4f_rg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../libs/durklang/com4f_rg-webfont.woff') format('woff'),
         url('../libs/durklang/com4f_rg-webfont.ttf') format('truetype'),
         url('../libs/durklang/com4f_rg-webfont.svg#com4t_fine_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

html,body{
	height:100%;
	font-size:1.0rem;
	padding:0;
	margin:0;
}

a{
	cursor:pointer;
	color:@link-color;
}

a:hover{
	cursor:pointer;
	//color:@link-hover-color;
	text-decoration:none;
}

a:active,
a:focus{
	cursor:pointer;
	color:@link-active-color;
	text-decoration:none;
}

.dk-absolute{
	position:absolute;
}

.dk-relative{
	position:relative;
}



.dk-overlay-box{
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
}



/* ProgressBar */
/*************************************************************************/

.progressbox{
	position:fixed;
	display:none; /*<<<<<<<<<<<<<<<<<<*/
	width:70%;
	max-width:700px;
	top:0;
	left:0;
	bottom:0;
	right:0;
	margin:auto;
	height:60%;
	border-radius:0.5rem;
	
z-index:99;
background:rgba(0,0,0,0.8);
}


.progressbox-container{
	position:relative;
	display:block;
	width:100%;
	height:100%;

}

.progressbar-container{
	position:absolute;
	width:90%;
	display:table;
	height:1.0rem;
	border:1px solid #747474;
	margin-bottom:10%;
	border-radius:2px;
	background:#555;
	margin-top:12%;	
	margin-left:5%;
}


/*
Wenn Übetragung erfolgreich, wird background per JS in blau geändert. 
Siehe sendContact() 
*/
.progressbar{
	position:absolute;
	top:0px;
	left:0px;
	width:0%;
	height:100%;
	background:#cc0000;
	border-radius:3px;
}

.progresstext-transfer,
.progresstext-send{
	position:relative;
	display:table-cell;
	top:0;	
	right:0;
	left:0;
	bottom:0;
	margin:auto;
	font-size:0.8rem;
	color:#000;
	text-align:center;
	vertical-align:middle;
	width:100%;
}

.progresstext-send{
	display:none;
	color:#000;
}

.progresssuccess{
	position:absolute;
	top:35%;
	padding-left:1%;
	padding-right:1%;
	padding:10%;
	width:90%;
	left:5%;
	display:none;
	border-radius:3px;
	line-height:1.3em;
	border: 0.2em dotted rgb(192, 192, 192);
	border:1px solid #747474;
	zfont-size:0.9em;
	background:#555;
	color:#ddd;
}


.progresssuccess.text{

}



/* floating DIV-BOXEN */
/*************************************************************************/


.dk-float-box{
	display:inline-block;
	margin-right:-4px;
	background:#ffcc00;
}

/* TEXT-BOX */
/*************************************************************************/


.dk-text-box{
	display:table;
	height:100%;
	width:100%;
}

.dk-text-box .dk-text{	display: table-cell; }

.dk-text-box .dk-text.centered{
	vertical-align: middle;
	text-align:center;
}

.dk-text-box .dk-text.top{ vertical-align: top; }
.dk-text-box .dk-text.bottom{ vertical-align: bottom; }
.dk-text-box .dk-text.middle{ vertical-align: middle; }
.dk-text-box .dk-text.left{ text-align:left; }
.dk-text-box .dk-text.right{ text-align:right; }
.dk-text-box .dk-text.center{ text-align:center; }


/* /TEXT-BOX */
/*************************************************************************/


/* QUAD-BOX */
/*************************************************************************/

.dk-quad-box{
	width:100%;
}

.dk-quad-box:before{ 
    content: ""; 
    display: block; 
    padding-top:100%; /* initial ratio of 1:1*/
}

.dk-quad-box-content{
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
}

/* /QUAD-BOX */
/*************************************************************************/


/* CONSOLE */
/*************************************************************************/

.dk-console{
	position:fixed;
	background:#000;
	font-size:12px;
	font-family:courier;
	padding:10px;
	border:1px solid #ccc;
	border-radius:5px;
	color:#eee;
	z-index:999999;
}

.dk-console.top{ top:0; }
.dk-console.bottom{ bottom:0; }
.dk-console.left{ left:0; }
.dk-console.right{ right:0; }

.dk-console.width25{ width:25%; }
.dk-console.width50{ width:50%; }
.dk-console.width75{ width:75%; }
.dk-console.width100{ width:100%; }

.dk-console.height100{ height:100px; }
.dk-console.height200{ height:200px; }
.dk-console.height400{ height:400px; }
.dk-console.height500{ height:500px; }


/* /CONSOLE */
/*************************************************************************/



/* FONT-SIZE */
/*************************************************************************/

@media only screen and (min-width: 0px) {
	.dk-fs10{ font-size:1.0rem }
	.dk-fs20{ font-size:2.0rem }
	.dk-fs25{ font-size:2.5rem }
	.dk-fs30{ font-size:3.0rem }
	.dk-fs40{ font-size:4.0rem }
	.dk-fs50{ font-size:5.0rem }
}

@media only screen and (min-width: 400px) {
	.dk-fs10{ font-size:1.1rem }
	.dk-fs20{ font-size:2.2rem }
	.dk-fs30{ font-size:3.3rem }
}

@media only screen and (min-width: 500px) {
	.dk-fs10{ font-size:1.2rem }
	.dk-fs20{ font-size:2.4rem }
	.dk-fs30{ font-size:3.6rem }
}


@media only screen and (min-width: 600px) {
	.dk-fs10{ font-size:1.3rem }
	.dk-fs20{ font-size:2.6rem }
	.dk-fs30{ font-size:3.9rem }
}

@media only screen and (min-width: 768px) {
	.dk-fs10{ font-size:1.4rem }
	.dk-fs20{ font-size:2.8rem }
	.dk-fs30{ font-size:4.2rem }
}

@media only screen and (min-width: 1000px) {
	.dk-fs10{ font-size:1.5rem }
	.dk-fs20{ font-size:2.8rem }
	.dk-fs30{ font-size:4.5rem }
}

@media only screen and (min-width: 1200px) {
	.dk-fs10{ font-size:1.6rem }
	.dk-fs20{ font-size:3.0rem }
	.dk-fs30{ font-size:4.8rem }
}

@media only screen and (min-width: 1400px) {
	.dk-fs10{ font-size:1.7rem }
	.dk-fs20{ font-size:3.2rem }
	.dk-fs30{ font-size:5.1rem }
}

@media only screen and (min-width: 1600px) {
	.dk-fs10{ font-size:1.8rem }
	.dk-fs20{ font-size:3.4rem }
	.dk-fs30{ font-size:5.4rem }
}

/* /FONT-SIZE */
/*************************************************************************/


/* durklang Logo, Claim und Co */
/*************************************************************************/

.dk-designed{
}

.dk-logo{
	font-family:Com4FineReg;
	font-size:1.5em;
}

.dk-claim{
}



/* durklang Footer */
/*************************************************************************/

#site-container{
	position:relative;
	overflow-x:hidden;
}

#dk-footer{
	font-family:arial;
	font-size:1.2rem;
	font-weight:700;
	height:7%;
	min-height:40px;
	max-height:50px;
	width:100%;
	background:#000;
	position:fixed;
	bottom:0px;
	z-index:99;
	border-top:1px solid #fff;
	text-align:right;
	margin:0;
	padding-right:5%;
	padding-bottom:0.1%;
	color:#aaa;
}

#dk-footer:hover{
	background:#222;
}

#dk-footer a{
	color:#666;
	text-decoration:none;
}

#dk-footer a:hover {
	ztext-decoration:none;
	zcolor:#fff;
}

#dk-footer .hoverz:hover{
	color:#fff;
}


#dk-footer .dk-designed{
	font-size:0.8rem;
	position:relative;	
	letter-spacing:0.09rem;
}


#dk-footer .dk-logo{
	position:relative;
	font-size:1.8rem;
	font-weight:normal;
	font-family:Com4FineReg;
	letter-spacing:0.05rem;
	margin-left:0.5rem;
	margin-right:0.3rem;
}



#dk-footer .dk-claim{
	position:relative;
	font-size:0.8rem;
	letter-spacing:0.09rem;
}


@media only screen and (max-width: 600px) {
	#dk-footer .dk-claim{
		display:none;
	}
}

/* NAV  */
/*************************************************************************/



/* script-check */
/*************************************************************************/
@keyframes dk-script-check {
    from {  transform: translateY(-500px)	}
    to   {  transform: translateY(0px) 		}
}

#dk-script-check{
	position:fixed;
	top:0;
	left:0%;
	z-index:90;
	width:100%;
	height:auto;
	padding:2px;
	background:rgba(170,0,0,0.99);
	border-bottom:2px solid #fff;
	text-align:center;
	color:#fff;
	letter-spacing:0.3pt;
	font-size:1.0rem;
	padding-top:0.5rem;
	padding-bottom:0.5rem;

	animation-name: dk-script-check; 
    animation-duration: 2s;        
    animation-iteration-count: 1;  
	animation-timing-function:ease-in-out;

}




//*************************************************************************

.dk-row{
	width:100%;
	display:table;
}

.dk-row .dk-col{
	display:table-cell;
	vertical-align:top;
}

.dk-width-100{ width:100%; }
.dk-width-90{ width:90%; }
.dk-width-80{ width:80%; }
.dk-width-75{ width:75%; }
.dk-width-66{ width:66%; }
.dk-width-60{ width:60%; }
.dk-width-50{ width:50%; }
.dk-width-40{ width:40%; }
.dk-width-33{ width:33%; }
.dk-width-30{ width:30%; }
.dk-width-25{ width:25%; }
.dk-width-20{ width:20%; }
.dk-width-10{ width:10%; }

.no-padding{
	padding:0;
}
