
// Nav-Slider (Menü-Slider)
// ==================================================================

.dk-nav-slider {
	position:absolute;
	z-index:91;
	width:250px;
	height:auto;
	overflow:auto;
	zbackground:rgba(130,10,10,0.7);
	background:rgba(0,0,0,0.7);
	border-left:2px solid #fff;
	border-bottom:2px solid #fff;
	border-bottom-left-radius:0.7rem;
	font-size:1.3rem;
	color:#fff;
}

.dk-nav-slider.from-right{
	right:-250px;
	top:0;
	transition: right 0.5s ease-in-out;	
}

.dk-nav-slider.from-right.show{
	right:0px;
}

.dk-nav-slider.from-top{
	right:0;
	top:-500px;
	transition: top 1s ease-in-out;	
	
}

.dk-nav-slider.from-top.show{
	top:0px;
}
	
.dk-nav-slider ul { 
	list-style: none;
	margin:0;
	padding:0;
}

.dk-nav-slider a{
	color:#fff;
	padding:0.5rem;
	padding-left:1.9rem;
	padding-right:0.9rem;
	border-bottom:1px solid #fff;
	display:block;
	text-decoration:none;
}

.dk-nav-slider a:hover,
.dk-nav-slider-close:hover{	
	background:@link-hover-color;
	opacity:0.9;	
}

.dk-nav-slider-close{
	position:relative;
 	display:table;
	width:20%;
	height:1.0rem;
	margin-top:1.7rem;
	margin-bottom:1.7rem;
	margin-left:auto;
	margin-right:auto;
	border-radius:0.3rem;
}

.dk-nav-slider-close:before{
	content:"✕";
	display: table-cell;
    vertical-align: middle;
    text-align:center;
	border:1px solid #fff;
	border-radius:0.3rem;
	width:1.0rem;
	cursor:pointer;
	padding:0.5rem;
	padding-left:0.8rem;
	padding-right:0.8rem;
}

