
// Bootstrap Configs
// ==================================================================
@panel-gradient-color:@link-color+30;
@carousel-caption-p-font-size:0.9rem;
@carousel-photo-creator-font-size:0.6rem;

.panel-group .panel-heading {
    background:@link-color;    
    background:-moz-linear-gradient(left,@link-color,@panel-gradient-color); /* Firefox */
    background:-webkit-gradient(linear,right,left,from(@link-color),to(@panel-gradient-color)); /* Chrome, Safari */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@link-color',endColorstr='@panel-gradient-color'); /* Internet Explorer */
    color:#fff;
    border:0px;
}

.panel-group .panel-heading:hover{
    background:@link-hover-color;
    color:#fff;
}

.panel-group h4{
    font-size:0.9rem;  
}

.panel-group .panel-heading a{
    display:block;
    text-decoration:none;
}


.panel .panel-body{
    padding:0;
    margin-top:0.8rem;
    margin-bottom:0.2rem;
    padding-left:0.2rem;
    border-top: 0px solid #000 !important;
}

.panel .panel-body p{
    
}
.carousel a{
    zbackground:@link-color;    
}

.carousel-inner .item{
    margin-right:0px;   
}

.carousel-inner .item img{
    border-bottom:1px solid #fff;    
}

.carousel-inner .item .creator {
    position:absolute;
    bottom:10px;
    left:10px;
    color:#fff;
    padding-left:30px;
    font-size:@carousel-photo-creator-font-size;
}

.carousel-inner .item .creator:before {
    content:"Foto: ";
    position:absolute;
    top:0px;
    left:0px;
}

.carousel-caption h4 {
    letter-spacing:0.07rem;
}

.carousel-caption p{
    font-size:@carousel-caption-p-font-size;
    letter-spacing:0.07rem;
}
.carousel-caption-bg{
    background:#825E3C;
    width:100%;
    height:150px;
}

.carousel-indicators{
    bottom:0px;
}