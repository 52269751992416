// Window
// ==================================================================

.dk-window {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.9);
	z-index:9999;
}

.dk-windows-freez-body{
	overflow:hidden;
}

.dk-window-top{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:10%;
	display:block;
	padding:0px;
	padding-left:30px;
	padding-right:30px;
	background:#192051;
	border-bottom:1px solid #fff;
	margin-bottom:20px;
zbackground:#ff00cc;
}

.dk-window-top-left, 
.dk-window-top-right{
	display:inline-block;
	width:49%;
	height:100%;
border:1px solid red;
}

.dk-window-top-left{
	width:69%;
}
.dk-window-top-right{
	width:28%;
}

.dk-window-title{
	position:absolute;	
	width:100%;
	height:1.1em;
	margin:auto;
	top:0;
	bottom:0;
	left:30px;
	right:0;
	color:#fff;
	font-size:0.9em;
zbackground:#00ff00;
}


.dk-window-close{
	position:absolute;
	margin:auto;
	zwidth:70%;
	height:1.55em;
	top:0;
	bottom:0;
	right:30px;
	left:auto;
	text-align:right;
zbackground:#ffcc00;
}

.dk-window-close-btn,.dk-window-close-btn-small{	
	cursor:pointer;
	width:100%;
	max-width:150px;
	color:#6972AF;
	padding:5px;
	font-size:0.7em;
	border:2px solid #6972AF;
	border-radius:5px;
	background:#303972;
	margin-left:auto;
	margin-right:0;
	text-align:center;
}

.dk-window-close-btn-small{
	max-width:30px;
}

.dk-window-close-btn-small:hover,
.dk-window-close-btn:hover{
	color:#fff;
	border-color:#fff;
}

.dk-window-close-btn-icon{
	display:inline-block;
}

.dk-window-close-btn-text{
	display:inline-block;
}

.dk-window-content-area{
	position:absolute;
	top:10%;
	left:0;
	right:0;
	bottom:0;
	margin:auto;
	width:100%;
	height:90%;
	padding:30px;
	overflow-y: scroll;
}

.dk-window-content{
	width:100%;
	max-width:600px;
	color:#fff;
	font-size:0.65em;
}

.dk-window-content h3:first-of-type{
	margin-top:0.0em;
}

.dk-window-content h3{
	font-size:1.5em;
	margin-top:3.0em;
}


.dk-window-content h4{
	font-size:1.1em;
	font-weight:bold;
	margin-top:1.9em;
	margin-bottom:0.5em;
}

.dk-window-content p{
	font-size:0.9em;
}

.dk-window-content a{
	color:#fff;
	text-decoration:none;
}

.dk-window-content a:hover{
	color:#fff;
	text-decoration:underline;
}

