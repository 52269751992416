
// durklangs CORNER-BUTTON
// ==================================================================

// Parameter
// ------------------------------------------------------------------
@corner-button-before-text:"Menü";

// Animation - Blitz
// Für das erste mal, wenn der Button (die Seite) geladen wird
@keyframes dk-corner-button-flash {
    0% 		{box-shadow: 0px 0px 0px 0px @corner-button-color;}
    40% 	{box-shadow: 0px 0px 20px 10px @corner-button-color;}
    100% 	{box-shadow: 0px 0px 0px 0px @corner-button-color;}
}

// Animation - Puls
// Als Loop für Zwischendurch
@keyframes dk-corner-button-puls {
    0% 		{box-shadow: 0px 0px 0px 0px @corner-button-color;}
    5% 		{box-shadow: 0px 0px 50px 1px @corner-button-color;}
    10% 	{box-shadow: 0px 0px 0px 0px @corner-button-color;}
    100% 	{box-shadow: 0px 0px 0px 0px @corner-button-color;}
}

// Animation - Ausblenden
// Für den Menütext
@keyframes dk-corner-button-fadeout {
	from{opacity:1;}
	to{opacity:0;}
}

.dk-corner-button-area{
	position:absolute;
	cursor:pointer;
	width:8rem;
	height:5rem;
	z-index:91;
	zbackground:rgba(200,200,200,0.1);
	background:#ccc;
	opacity:0.1;
	box-shadow: 0px 0px 20px 5px #ccc;
	border-radius:4.0rem;
}

.dk-corner-button-area:hover{
    background:#00cc00;
}

.dk-corner-button-area.top-right{
	top:-1.0rem;
	right:-1.0rem;
}

.dk-corner-button-area.top-right.half-circle{
	top:-1.0rem;
	right:-1.0rem;
}

.dk-corner-button-area.top-left{
	top:0rem;
	left:0rem;
}

.dk-corner-button.animation-start{
	animation-name: dk-corner-button-flash;
    animation-duration: 0.5s;
	animation-iteration-count: 3;
	animation-timing-function: ease-out;
	animation-delay: 2s;
}

.dk-corner-button.animation-loop{
	animation-name: dk-corner-button-puls;
    animation-duration: 7s;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
}


.dk-corner-button{
	position:absolute;
	background: @corner-button-color;
	zopacity:0.7;
	border-radius:200px;
	width:2.5rem;
	height:2.5rem;
	zbox-shadow: 0px 0px 10px 0px #ff0000;
	z-index:80;
}

.dk-corner-button:hover{
    background: @corner-button-hover-color;
}

.dk-corner-button.top-right{
	top:-1.5rem;
	right:-1.5rem;
}

.dk-corner-button.top-right.half-circle{
	top:-1.5rem;
	right:1.5rem;
}

.dk-corner-button.top-left{
	top:-1.5rem;
	left:-1.5rem;
}

.dk-corner-button:before{
	animation-name: dk-corner-button-fadeout;
    animation-duration: 10s;
	animation-iteration-count:1;
	animation-timing-function: ease-in;

	content:@corner-button-before-text;
	color:@corner-button-color;
	position:absolute;
	top:3.0rem;
	right:2.5rem;
	font-size:0.7rem;
	letter-spacing:0.07rem;
	opacity:0;
}