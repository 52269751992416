// Human-Check
// ==================================================================


.dk-humcheck-info{
	line-height:1.2em;
	font-size:0.9em;
}

.dk-humcheck-number,
.dk-humcheck-operation{
	background:#ddd;
	padding-left:0.25em;
	padding-right:0.2em;
	border:1px dotted #777;
	border-radius:3px;
}

.dk-humcheck-operation{

}
